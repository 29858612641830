import { Shipment } from '../api';
import { sortByDate } from './general';

/**
 * Filters and sorts shipments from the last 12 months
 * @param shipments Array of shipments to filter
 * @returns Filtered array of shipments from last 12 months, sorted by transport date in descending order (newest first)
 */
export const filterRecentShipments = (shipments: Shipment[]): Shipment[] => {
  const twelveMonthsAgo = new Date();
  twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);

  return shipments
    .filter(
      ({ transportDate }) =>
        transportDate && new Date(transportDate) > twelveMonthsAgo
    )
    .sort((a, b) => {
      return sortByDate(a.transportDate, b.transportDate);
    });
};
