import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { LoadingScreen } from '../../components';
import { useUser } from '../../hooks/api';

export const SignOutOidc = () => {
  const { signoutRedirect } = useAuth();
  const { signout } = useUser();

  useEffect(() => {
    const handleSignout = async () => {
      signout();
      await signoutRedirect();
    };
    handleSignout();
  }, [signoutRedirect, signout]);

  return <LoadingScreen />;
};
