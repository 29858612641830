const colors = {
  background: '#eeeeee',
  primary: '#243683',
  primaryDark: '#203075',
  primaryLight: '#FFFFFF14',
  secondary: '#f38228',
  white: '#ffffff',
  black: '#000000',
  gray: '#808080',
  lightGray: '#f6f6f6',
  darkGray: '#404854',
  success: '#2E8B57',
  successLight: '#dff0d8',
  error: '#DC143C',
  errorLight: '#f2dede',
};

export default colors;
