import { Skeleton } from '@mui/material';
import { Stack } from '@mui/system';

export const ArticleDataSkeleton = () => {
  return (
    <>
      <Stack spacing={1} style={{ maxWidth: '100%' }}>
        <Skeleton variant={'rectangular'} width={250} height={30} />
        <Skeleton variant={'rectangular'} width={200} height={20} />
      </Stack>
    </>
  );
};
