import { Grid, Typography } from '@mui/material';

import { ContactForm, PageHeader, PaperContainer } from '../components';

export function Messages() {
  return (
    <>
      <PageHeader
        heading="Meddelanden"
        text="Här kan du se alla dina meddelanden från ATORP"
      />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography component="h2" variant="h5" style={{ marginBottom: 10 }}>
            Skicka ett meddelande
          </Typography>
          <PaperContainer className="Messages">
            <ContactForm />
          </PaperContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography component="h2" variant="h5" style={{ marginBottom: 10 }}>
            Kontakta ATORP
          </Typography>
          <PaperContainer className="Messages">
            <span>
              Du kan enkelt kontakta oss genom att antingen använda formuläret
              på denna sidan eller genom att ringa oss på 0370-50 15 00.
            </span>
            <div style={{ marginTop: 25 }}>
              <Typography component="h2" variant="h6">
                Leveransadress
              </Typography>
              <span>Industrivägen 7</span>
              <br />
              <span>333 72 Bredaryd</span>
            </div>
          </PaperContainer>
        </Grid>
      </Grid>
    </>
  );
}
