/**
 * Returns accessibility props for a tab element
 * @param index - The index of the tab
 * @returns Object containing id and aria-controls attributes for accessibility
 */
export const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};
