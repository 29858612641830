import {
  Alert,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { UsersService } from '../../api';
import { FlattenedUserProfile } from '../../components';
import { renderTextIfDefined } from '../../helpers';
import { useToast } from '../../hooks/helpers';

type Props = {
  users: FlattenedUserProfile[];
};

export const UserTable = (props: Props) => {
  const { users } = props || {};
  const { enqueueToastMessage } = useToast();

  const handleOnClickResetPassword = async (user?: FlattenedUserProfile) => {
    const { id: userId, emailAddress } = user || {};
    try {
      if (!userId) {
        return;
      }
      await UsersService.postUsersTriggerForgotPassword(userId);
      enqueueToastMessage(
        `Ett återställningsmejl har skickats till ${emailAddress}!`,
        'success'
      );
    } catch (e) {
      enqueueToastMessage(
        `Kunde inte begära lösenordsåterställning för ${emailAddress}!`,
        'error'
      );
      console.error(e);
    }
  };

  return users.length > 0 ? (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head">Namn</TableCell>
            <TableCell variant="head">E-postadress</TableCell>
            <TableCell variant="head">Kund</TableCell>
            <TableCell variant="head">Telefon</TableCell>
            <TableCell variant="head">Mobil</TableCell>
            <TableCell variant="head">Aktiv</TableCell>
            <TableCell variant="head">Administratör</TableCell>
            <TableCell variant="head"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, i) => {
            const {
              active,
              cellPhoneNumber,
              companyCode,
              companyName,
              emailAddress,
              isAdmin,
              name,
              phoneNumber,
            } = user || {};
            return (
              <TableRow key={i}>
                <TableCell>{renderTextIfDefined(name)}</TableCell>
                <TableCell>{renderTextIfDefined(emailAddress)}</TableCell>
                <TableCell>
                  {renderTextIfDefined(
                    `${companyCode ?? ''} ${companyName ?? ''}`
                  )}
                </TableCell>
                <TableCell>{renderTextIfDefined(phoneNumber)}</TableCell>
                <TableCell>{renderTextIfDefined(cellPhoneNumber)}</TableCell>
                <TableCell>{active ? 'Ja' : 'Nej'}</TableCell>
                <TableCell>{isAdmin ? 'Ja' : 'Nej'}</TableCell>
                <TableCell sx={{ padding: 0, paddingRight: 2 }} align="right">
                  <Button
                    onClick={() => {
                      handleOnClickResetPassword(user);
                    }}
                  >
                    Återställ lösenord
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Alert severity="info">{'Inga användare hittades!'}</Alert>
  );
};
