import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CustomerOrder } from '../../api';
import { ROUTE_PATHS } from '../../constants/routes';
import { formatDate, renderTextIfDefined } from '../../helpers';

type Props = { orders: CustomerOrder[]; renderArticleLink?: boolean };

export const OrdersTable = (props: Props) => {
  const { orders, renderArticleLink = true } = props || {};
  const navigate = useNavigate();

  const handleOnClickArticleNumber = (partId?: string) => {
    partId && navigate(ROUTE_PATHS.ARTICLE(partId.toString()));
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head">Datum</TableCell>
            <TableCell variant="head">Artikel#</TableCell>
            <TableCell variant="head">Kund Artikel#</TableCell>
            <TableCell variant="head">Antal artiklar</TableCell>
            <TableCell variant="head">Order#</TableCell>
            <TableCell variant="head">Kund Order#</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, i) => {
            const { customerOrderNumber, orderNumber, part, quantity, period } =
              order || {};
            const { customerPartNumber, partNumber, id: partId } = part || {};

            return (
              <TableRow key={i}>
                <TableCell>{period && formatDate(period)}</TableCell>
                {renderArticleLink ? (
                  <TableCell>
                    <Link
                      component="button"
                      onClick={() => handleOnClickArticleNumber(partId)}
                    >
                      {renderTextIfDefined(partNumber)}
                    </Link>
                  </TableCell>
                ) : (
                  <TableCell>{renderTextIfDefined(partNumber)}</TableCell>
                )}
                <TableCell>{renderTextIfDefined(customerPartNumber)}</TableCell>
                <TableCell>{renderTextIfDefined(quantity, 'st')}</TableCell>
                <TableCell>{renderTextIfDefined(orderNumber)}</TableCell>
                <TableCell>
                  {renderTextIfDefined(customerOrderNumber)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
