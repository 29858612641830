/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserProfile } from '../models/UserProfile';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
    /**
     * @returns UserProfile Success
     * @throws ApiError
     */
    public static getUsers(): CancelablePromise<Array<UserProfile>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users',
        });
    }
    /**
     * @param userId
     * @returns any Success
     * @throws ApiError
     */
    public static postUsersDeactivate(
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{userId}/deactivate',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns any Success
     * @throws ApiError
     */
    public static postUsersActivate(
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{userId}/activate',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns any Success
     * @throws ApiError
     */
    public static postUsersGrantAdmin(
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{userId}/grantAdmin',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns any Success
     * @throws ApiError
     */
    public static postUsersRevokeAdmin(
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{userId}/revokeAdmin',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns any Success
     * @throws ApiError
     */
    public static postUsersTriggerForgotPassword(
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{userId}/triggerForgotPassword',
            path: {
                'userId': userId,
            },
        });
    }
}
