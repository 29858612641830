import { TextField } from '@mui/material';
import { useMemo } from 'react';
import { UserProfile } from '../../api';
import { useAdmin } from '../../hooks/api';
import { useFilterArray, useSearchQuery } from '../../hooks/helpers';
import { PaperContainer } from '../Paper/PaperContainer';
import { UserTable } from './UserTable';

export type FlattenedUserProfile = Omit<UserProfile, 'customer'> & {
  companyCode?: UserProfile['customer'] extends undefined
    ? undefined
    : NonNullable<UserProfile['customer']>['code'];
  companyName?: UserProfile['customer'] extends undefined
    ? undefined
    : NonNullable<UserProfile['customer']>['name'];
};

export const UserTableContainer = () => {
  const { users } = useAdmin();

  const { handleOnChange, query } = useSearchQuery();

  const propertyNames: (keyof FlattenedUserProfile)[] = useMemo(
    () => [
      'name',
      'emailAddress',
      'phoneNumber',
      'cellPhoneNumber',
      'companyCode',
      'companyName',
    ],
    []
  );

  const flattenedUserArray: FlattenedUserProfile[] = useMemo(
    () =>
      users.map(({ customer, ...rest }) => ({
        ...rest,
        companyCode: customer?.code,
        companyName: customer?.name,
      })),
    [users]
  );

  const filteredUsers = useFilterArray(
    flattenedUserArray,
    query,
    propertyNames
  );

  return (
    <PaperContainer>
      <TextField
        id="outlined-basic"
        label="Skriv in vad du vill söka på..."
        variant="outlined"
        style={{ marginBottom: 16 }}
        fullWidth
        value={query}
        onChange={handleOnChange}
      />
      <UserTable users={filteredUsers} />
    </PaperContainer>
  );
};
