import { Close } from '@mui/icons-material';
import { Fade, Modal } from '@mui/material';
import React, { CSSProperties } from 'react';
import ReactPlayer from 'react-player/lazy';
import colors from '../../constants/colors';

type Props = {
  coverUrl: string;
  url: string;
  showAsModal: boolean;
};

export function VideoPlayer({ coverUrl, url, showAsModal }: Props) {
  const [showModal, setShowModal] = React.useState(false);

  const toggleModal = React.useCallback(() => {
    setShowModal((prevShow) => !prevShow);
  }, []);

  if (!url) {
    return null;
  }

  return (
    <>
      {showAsModal && (
        <>
          <img
            onClick={toggleModal}
            src={coverUrl}
            alt="Bild för instruktionsfilm för portalen"
            style={styles.modalTrigger}
          />

          <Modal
            style={styles.modal}
            aria-labelledby="Modal video window"
            aria-describedby="A modal window implementing a video player that plays a video"
            open={showModal}
            onClose={toggleModal}
            closeAfterTransition
          >
            <Fade in={showModal}>
              <div style={styles.modalContent}>
                <ReactPlayer
                  url={url}
                  controls
                  volume={0.3}
                  playIcon={<div />}
                  playing={true}
                  style={styles.video}
                />
                <Close style={styles.closeIcon} onClick={toggleModal} />
              </div>
            </Fade>
          </Modal>
        </>
      )}
      {!showAsModal && (
        <ReactPlayer
          url={url}
          controls
          volume={0.3}
          light={coverUrl ?? true}
          playIcon={<div />}
          playsinline={true}
          playing={true}
        />
      )}
    </>
  );
}

const styles: Record<string, CSSProperties> = {
  closeIcon: {
    color: colors.white,
    position: 'absolute',
    top: -40,
    right: 0,
    cursor: 'pointer',
  },
  modalTrigger: {
    width: '100%',
    cursor: 'pointer',
  },
  icon: {
    marginLeft: 5,
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  modalContent: {
    maxWidth: '84%',
    outline: 0,
    border: 0,
    marginLeft: '8%',
    marginRight: '8%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  video: {
    width: '100%',
    height: 'auto',
    maxWidth: '100%',
    margin: '0 auto',
    position: 'relative',
  },
};
