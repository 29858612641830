import { Skeleton, SkeletonProps } from '@mui/material';

export const ArticleStockSkeleton = (props: SkeletonProps) => {
  const {
    width = 250,
    height = 28,
    variant = 'rectangular',
    ...rest
  } = props || {};

  return (
    <Skeleton
      style={{ maxWidth: '100%' }}
      variant={variant}
      width={width}
      height={height}
      {...rest}
    />
  );
};
