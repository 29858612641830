import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from '../../components';

export const SignInOidc = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return <LoadingScreen />;
};
