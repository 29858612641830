import { RouteProps } from 'react-router-dom';
import { NotFound } from '../components/NotFound/NotFound';
import {
  Admin,
  Article,
  Articles,
  Dashboard,
  Deliveries,
  Messages,
  Orders,
  PortalInfo,
  Profile,
  SignInOidc,
  SignOutOidc,
} from '../pages';

export type AppRoute = {
  path: string;
  component: React.ComponentType<any>;
} & RouteProps;

export const ROUTE_PATHS = {
  DASHBOARD: '/',
  SIGN_IN_OIDC: '/signin-oidc',
  SIGN_OUT_OIDC: '/signout-oidc',
  ARTICLES: '/articles',
  ARTICLE: (id: string) => `/article/${id}`,
  DELIVERIES: '/deliveries',
  ORDERS: '/orders',
  PROFILE: '/profile',
  MESSAGES: '/messages',
  PORTAL_INFO: '/portalinfo',
  ADMIN: '/admin',
};

export const ROUTES: AppRoute[] = [
  { path: ROUTE_PATHS.DASHBOARD, component: Dashboard },
  { path: ROUTE_PATHS.SIGN_IN_OIDC, component: SignInOidc },
  { path: ROUTE_PATHS.SIGN_OUT_OIDC, component: SignOutOidc },
  { path: ROUTE_PATHS.ARTICLES, component: Articles },
  { path: ROUTE_PATHS.ARTICLE(':id'), component: Article },
  { path: ROUTE_PATHS.DELIVERIES, component: Deliveries },
  { path: ROUTE_PATHS.ORDERS, component: Orders },
  { path: ROUTE_PATHS.PROFILE, component: Profile },
  { path: ROUTE_PATHS.MESSAGES, component: Messages },
  { path: ROUTE_PATHS.PORTAL_INFO, component: PortalInfo },
  { path: ROUTE_PATHS.ADMIN, component: Admin },
  { path: '*', component: NotFound },
];
