import { useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import './App';
import { PortalApp } from './Portal.App';
import { OpenAPI } from './api';
import { LoadingScreen } from './components/Loading/LoadingScreen';
import { useUser } from './hooks/api';

function App() {
  const {
    user,
    signinRedirect,
    isAuthenticated,
    isLoading,
    events,
    removeUser,
    signoutRedirect,
  } = useAuth();
  const { access_token, expires_at } = user || {};
  const { signout } = useUser();

  useEffect(() => {
    const checkForExpiredToken = async () => {
      if (
        access_token &&
        expires_at &&
        new Date(expires_at * 1000) < new Date()
      ) {
        signout();
        await signoutRedirect();
      }
    };
    checkForExpiredToken();
  }, [access_token, expires_at, signoutRedirect, signout]);

  useEffect(() => {
    return events.addAccessTokenExpired(async () => {
      signout();
      await signoutRedirect();
    });
  }, [events, signoutRedirect, removeUser, signout]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !hasAuthParams()) {
      const timeoutId = setTimeout(async () => {
        await signinRedirect();
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isAuthenticated, isLoading, signinRedirect]);

  useEffect(() => {
    OpenAPI.TOKEN = access_token;
    OpenAPI.BASE =
      process.env.REACT_APP_API_URL ??
      'https://app-atorp-dev-secentral.azurewebsites.net';
  }, [access_token]);

  if (!isAuthenticated || isLoading) {
    return <LoadingScreen />;
  }

  if (isAuthenticated && !isLoading) {
    return <PortalApp />;
  }
}

export default App;
