/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Part } from '../models/Part';
import type { PartDetails } from '../models/PartDetails';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PartsService {
    /**
     * @param customerId
     * @returns Part Success
     * @throws ApiError
     */
    public static getCustomersParts(
        customerId: string,
    ): CancelablePromise<Array<Part>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customerId}/parts',
            path: {
                'customerId': customerId,
            },
        });
    }
    /**
     * @param customerId
     * @param partId
     * @returns PartDetails Success
     * @throws ApiError
     */
    public static getCustomersPartsDetails(
        customerId: string,
        partId: string,
    ): CancelablePromise<PartDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customerId}/parts/{partId}/details',
            path: {
                'customerId': customerId,
                'partId': partId,
            },
        });
    }
}
