import { PayloadAction, configureStore, createSlice } from '@reduxjs/toolkit';
import {
  Customer,
  CustomerOrder,
  OpenAPI,
  Part,
  Shipment,
  UserProfile,
} from '../api';
import { filterRecentShipments } from '../helpers';

import {
  getUserLocalStorage,
  setSessionCustomerCode,
  setUserLocalStorage,
} from './../helpers/user';

const initialUserState: { user: UserProfile | null } = {
  user: getUserLocalStorage(),
};
const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser: (state, action: PayloadAction<UserProfile>) => {
      state.user = action.payload;
      setUserLocalStorage(action.payload);
      setSessionCustomerCode(action.payload.customer?.code || '');
    },
    updateUser: (state, action: PayloadAction<UserProfile>) => {
      state.user = action.payload;
      setUserLocalStorage(action.payload);
      setSessionCustomerCode(action.payload.customer?.code || '');
    },
    signoutUser: (state) => {
      state.user = null;
      localStorage.clear();
      sessionStorage.clear();
      OpenAPI.TOKEN = '';
    },
  },
});

export const { setUser, updateUser, signoutUser } = userSlice.actions;

const initialCustomersState: { loading: boolean; customers: Customer[] } = {
  loading: true,
  customers: [],
};
const customersSlice = createSlice({
  name: 'customers',
  initialState: initialCustomersState,
  reducers: {
    setCustomers: (state, action: PayloadAction<Customer[]>) => {
      state.loading = false;
      state.customers = action.payload;
    },
    resetCustomers: () => {
      return initialCustomersState;
    },
  },
});

export const { setCustomers, resetCustomers } = customersSlice.actions;

const initialArticlesState: {
  loading: boolean;
  articles: Part[];
} = {
  loading: true,
  articles: [],
};
const articlesSlice = createSlice({
  name: 'articles',
  initialState: initialArticlesState,
  reducers: {
    setArticles: (state, action: PayloadAction<Part[]>) => {
      state.loading = false;
      state.articles = action.payload;
    },

    resetArticles: () => {
      return initialArticlesState;
    },
  },
});

export const { setArticles, resetArticles } = articlesSlice.actions;

const initialOrdersState: { loading: boolean; orders: CustomerOrder[] } = {
  loading: true,
  orders: [],
};
const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrdersState,
  reducers: {
    setOrders: (state, action: PayloadAction<CustomerOrder[]>) => {
      state.loading = false;
      state.orders = action.payload;
    },
    resetOrders: () => {
      return initialOrdersState;
    },
  },
});
export const { setOrders, resetOrders } = ordersSlice.actions;

const initialDeliveriesState: {
  loading: boolean;
  deliveries: Shipment[];
} = {
  loading: true,
  deliveries: [],
};
const deliveriesSlice = createSlice({
  name: 'deliveries',
  initialState: initialDeliveriesState,
  reducers: {
    setDeliveries: (state, action: PayloadAction<Shipment[]>) => {
      const filteredDeliveries = filterRecentShipments(action.payload);
      state.loading = false;
      state.deliveries = filteredDeliveries;
    },
    resetDeliveries: () => {
      return initialDeliveriesState;
    },
  },
});
export const { setDeliveries, resetDeliveries } = deliveriesSlice.actions;

const initialUsersState: { loading: boolean; users: UserProfile[] } = {
  loading: true,
  users: [],
};
const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsersState,
  reducers: {
    setUsers: (state, action: PayloadAction<UserProfile[]>) => {
      state.loading = false;
      state.users = action.payload;
    },
    resetUsers: () => {
      return initialUsersState;
    },
  },
});

export const { setUsers, resetUsers } = usersSlice.actions;

export const store = configureStore({
  reducer: {
    articles: articlesSlice.reducer,
    customers: customersSlice.reducer,
    deliveries: deliveriesSlice.reducer,
    orders: ordersSlice.reducer,
    user: userSlice.reducer,
    users: usersSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
