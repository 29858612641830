import { useCallback } from 'react';
import { getAllParts } from '../../services/api';
import { useAppSelector } from '../redux/useAppSelector';

export const useArticles = () => {
  const getArticles = useCallback(async () => {
    await getAllParts();
  }, []);

  const articles = useAppSelector((state) => state.articles);
  return { ...articles, getArticles };
};
