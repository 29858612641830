import {
  Alert,
  Box,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnhancedTableHead } from '..';
import { Part } from '../../api';
import { ROUTE_PATHS } from '../../constants/routes';

function descendingComparator(
  a: Part,
  b: Part,
  orderBy: TableOrderByKeys
): number {
  if (orderBy === '') {
    return 0;
  }

  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (valueB == null && valueA == null) {
    return 0;
  }
  if (valueB == null) {
    return -1;
  }
  if (valueA == null) {
    return 1;
  }

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparator(
  order: TableOrder,
  orderBy: TableOrderByKeys
): (a: Part, b: Part) => number {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Part[],
  comparator: (a: Part, b: Part) => number
): Part[] {
  const stabilizedThis: [Part, number][] = array.map((el, index) => [
    el,
    index,
  ]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  table: {
    boxSizing: 'border-box',
  },
}));

type Props = {
  data: Part[];
};

export type TableOrderByKeys =
  | keyof Pick<
      Part,
      'partNumber' | 'customerPartNumber' | 'description' | 'inventoryBalance'
    >
  | '';

export type TableOrder = 'asc' | 'desc';

export function ArticlesTable({ data }: Props) {
  const [order, setOrder] = React.useState<TableOrder>('asc');
  const [orderBy, setOrderBy] = React.useState<TableOrderByKeys>('');
  const [dense, setDense] = React.useState(false);
  const [stockFilter, setStockFilter] = React.useState(false);
  const navigate = useNavigate();

  let rows: Part[] = data;

  const handleRequestSort = (property: TableOrderByKeys) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    sessionStorage.setItem('sessionOrder', order === 'asc' ? 'desc' : 'asc');
    sessionStorage.setItem('sessionOrderBy', orderBy);
  };

  const handleArticleClick = (id: string) => {
    navigate(ROUTE_PATHS.ARTICLE(id));
  };

  const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setStockFilter(event.target.checked);
  };

  useEffect(() => {
    const sessionOrder = sessionStorage.getItem(
      'sessionOrder'
    ) as TableOrder | null;
    const sessionOrderBy = sessionStorage.getItem(
      'sessionOrderBy'
    ) as TableOrderByKeys | null;

    if (sessionOrderBy) {
      setOrderBy(sessionOrderBy);
    }

    if (sessionOrder) {
      setOrder(sessionOrder);
    }
  }, []);

  return (
    <Box>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Komprimera"
        style={{ margin: 0, padding: 0, marginTop: -25, marginBottom: -25 }}
      />
      <FormControlLabel
        control={<Switch checked={stockFilter} onChange={handleFilter} />}
        label="Visa enbart artiklar i lager"
        style={{ margin: 0, padding: 0, marginTop: -25, marginBottom: -25 }}
      />
      <Box marginTop={3}>
        {rows.length === 0 && (
          <Alert severity="info">{'Inga artiklar hittades!'}</Alert>
        )}
        {rows.length > 0 && (
          <StyledTableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .filter(
                    (x) =>
                      (stockFilter && Number(x.inventoryBalance) > 0) ||
                      !stockFilter
                  )
                  .map((row, index) => {
                    const {
                      id,
                      partNumber,
                      customerPartNumber,
                      description,
                      inventoryBalance,
                    } = row || {};
                    return (
                      <TableRow
                        hover
                        onClick={() => id && handleArticleClick(id)}
                        style={{ cursor: 'pointer' }}
                        key={index}
                      >
                        <TableCell>{description}</TableCell>
                        <TableCell align="right">
                          {customerPartNumber}
                        </TableCell>
                        <TableCell align="right">{partNumber}</TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color:
                              inventoryBalance && inventoryBalance > 0
                                ? 'mediumseagreen'
                                : 'crimson',
                          }}
                        >
                          {inventoryBalance}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </Box>
    </Box>
  );
}
