import { useCallback } from 'react';
import { getCustomerList } from '../../services/api';
import { useAppSelector } from '../redux/useAppSelector';

export const useCustomers = () => {
  const getCustomers = useCallback(async () => {
    await getCustomerList();
  }, []);

  const customers = useAppSelector((state) => state.customers);
  return { ...customers, getCustomers };
};
