import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { styled } from '@mui/system';
import { TableOrder, TableOrderByKeys } from '..';

const VisuallyHidden = styled('span')(({ theme }) => ({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
}));

export function EnhancedTableHead(props: {
  order: TableOrder;
  orderBy: TableOrderByKeys;
  onRequestSort: (property: TableOrderByKeys) => void;
  rowCount: number;
}) {
  const { order, orderBy, onRequestSort } = props || {};

  const createSortHandler = (property: TableOrderByKeys) => {
    onRequestSort(property);
  };

  const headCells: {
    id: TableOrderByKeys;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
  }[] = [
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Benämning',
    },
    {
      id: 'customerPartNumber',
      numeric: true,
      disablePadding: false,
      label: 'Artnr Kund',
    },
    {
      id: 'partNumber',
      numeric: true,
      disablePadding: false,
      label: 'Artnr ATORP',
    },
    {
      id: 'inventoryBalance',
      numeric: true,
      disablePadding: false,
      label: 'Lagersaldo',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              style={{ fontWeight: 'bold' }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <VisuallyHidden>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </VisuallyHidden>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
