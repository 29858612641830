export type PredefinedMessage = {
  desc: string;
  subject: string;
  body: string;
};

const message1: PredefinedMessage = {
  desc: 'Uppdatera pris',
  subject: 'Uppdatera pris med ny volym ',
  body: 'Fyll i vilken volym du vill ha pris på',
};

const message2: PredefinedMessage = {
  desc: 'Tidigare leverans',
  subject: 'Önskar tidigare leverans',
  body: 'Beskriv när och vad du vill ha levererat',
};

const message3: PredefinedMessage = {
  desc: 'Övriga frågor',
  subject: 'Övriga frågor eller info',
  body: 'Skriv ditt meddelanded angående artikeln',
};

export { message1, message2, message3 };
