import { useNavigate } from 'react-router-dom';
import { Counter } from '../../components';
import { ROUTE_PATHS } from '../../constants/routes';
import { useOrders } from '../../hooks/api';

export function OrdersCount() {
  const { orders, loading } = useOrders();
  const navigate = useNavigate();

  return (
    <Counter
      onClick={() => navigate(ROUTE_PATHS.ORDERS)}
      title="Antal orderrader"
      value={orders?.length}
      isLoading={loading}
    />
  );
}
