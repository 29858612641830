import { useNavigate } from 'react-router-dom';
import { Counter } from '../../components';
import { ROUTE_PATHS } from '../../constants/routes';
import { useArticles } from '../../hooks/api';

export function ArticlesCount() {
  const { articles, loading } = useArticles();
  const navigate = useNavigate();

  return (
    <Counter
      onClick={() => navigate(ROUTE_PATHS.ARTICLES)}
      title="Antal artiklar"
      value={articles?.length}
      isLoading={loading}
    />
  );
}
