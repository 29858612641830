import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { OIDAuthProvider } from './components/Auth/OIDAuthProvider';
import { theme } from './constants/theme';
import './index.css';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <OIDAuthProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </OIDAuthProvider>
  </Provider>
);

serviceWorker.unregister();
