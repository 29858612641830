import { ChangeEvent, useState } from 'react';

export const useSearchQuery = (initQuery = '') => {
  const [query, setQuery] = useState(initQuery);

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const query = e.target.value;
    setQuery(query);
  };

  const resetQuery = () => {
    setQuery('');
  };

  return {
    query,
    handleOnChange,
    resetQuery,
    setQuery,
  };
};
