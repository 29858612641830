import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { LoadingScreen } from './components/Loading/LoadingScreen';
import { delay } from './helpers';
import { useUser } from './hooks/api';
import Paperbase from './paperbase/Paperbase';
import { initApp } from './services/api';

export function PortalApp() {
  const [isLoading, setIsLoading] = useState(true);
  const { user, signoutRedirect } = useAuth();
  const { access_token } = user || {};
  const { signout } = useUser();
  useEffect(() => {
    const init = async () => {
      try {
        setIsLoading(true);
        await delay(150);
        await initApp();
        await delay(150);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        signout();
        await signoutRedirect();
      }
    };
    init();
  }, [access_token, signoutRedirect, signout]);

  if (isLoading) return <LoadingScreen />;

  return (
    <div className="Portal">
      <Paperbase />
    </div>
  );
}
