import { AlertProps, AlertSlotsAndSlotProps } from '@mui/material';
import { AlertMessage } from './AlertMessage';

type Props = { text?: string } & AlertProps & AlertSlotsAndSlotProps;

export const AlertNoPlannedOrdersFound = (props: Props) => {
  const { text = 'Inga planerade beställningar hittades!', ...rest } =
    props || {};
  return <AlertMessage {...rest}>{text}</AlertMessage>;
};
