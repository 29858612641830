import { Grid } from '@mui/material';
import { PropsWithChildren } from 'react';
import { PaperContainer, ProfileGridItem } from '../../components';

export function ProfileGrid({
  children,
}: PropsWithChildren<{
  children:
    | React.ReactElement<typeof ProfileGridItem>
    | React.ReactElement<typeof ProfileGridItem>[];
}>) {
  return (
    <PaperContainer>
      <Grid container spacing={3}>
        {children}
      </Grid>
    </PaperContainer>
  );
}
