import { Box, BoxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<BoxProps>;

export const Center = ({ children, ...rest }: Props) => {
  return (
    <Box
      width={'100%'}
      height={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      display={'flex'}
      {...rest}
    >
      {children}
    </Box>
  );
};
