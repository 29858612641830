import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from '../../constants/routes';

export function NotFound() {
  return (
    <>
      <Typography component="h1" variant="h4">
        Hoppsan!
      </Typography>
      <span style={{ marginTop: 10, marginBottom: 10, display: 'block' }}>
        Vi kunde tyvärr inte hitta innehållet du söker. Försök igen, eller
        kontakta ATORP om problemet kvarstår.
      </span>
      <Link style={{ display: 'block' }} to={ROUTE_PATHS.DASHBOARD}>
        Klicka här för att gå till startsidan
      </Link>
    </>
  );
}
