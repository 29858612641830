import { useCallback, useEffect, useState } from 'react';

export function useFilterArray<T>(
  array: T[],
  query: string,
  properties: (keyof T)[]
) {
  const [filteredArray, setFilteredArray] = useState<T[]>(array);

  const filterByQueryWords = useCallback(
    (query: string, array: T[]) => {
      const lowercaseQuery = query.toLowerCase();
      const queryWords = lowercaseQuery
        .split(' ')
        .filter((word) => word.length > 0);
      const filtered = array.filter((item) =>
        queryWords.every((word) =>
          properties.some((prop) => {
            const value = String(item[prop]).toLowerCase();
            return value.includes(word);
          })
        )
      );
      return filtered;
    },
    [properties]
  );

  useEffect(() => {
    const filteredArray = filterByQueryWords(query, array);
    setFilteredArray(filteredArray);
  }, [query, array, properties, filterByQueryWords]);

  return filteredArray;
}
