import { LinearProgress, Typography } from '@mui/material';
import {
  AlertNoOrdersFound,
  OrdersTable,
  PaperContainer,
} from '../../components';
import { useOrders } from '../../hooks/api';

export function LatestOrders() {
  const { orders, loading } = useOrders();
  const latestOrders = orders.slice(0, 5);

  return (
    <>
      <Typography style={{ marginBottom: 10 }} component="h5" variant="h5">
        Order för leverans
      </Typography>
      <PaperContainer>
        {loading ? (
          <LinearProgress />
        ) : latestOrders?.length > 0 ? (
          <OrdersTable orders={latestOrders} />
        ) : (
          <AlertNoOrdersFound />
        )}
      </PaperContainer>
    </>
  );
}
