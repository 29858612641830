import { ArrowForward } from '@mui/icons-material';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import CountUp from 'react-countup';
import colors from '../../constants/colors';

type Props = {
  title: string;
  value: number;
  isLoading?: boolean;
  onClick?: () => void;
};

export const Counter = (props: Props) => {
  const { title, value, onClick, isLoading } = props || {};

  return (
    <Box onClick={onClick} style={{ cursor: 'pointer' }}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <Typography component="h5" variant="h5">
          {title}
        </Typography>
        <ArrowForward />
      </Box>
      <Paper
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          minHeight: 300,
        }}
      >
        <Typography
          component="h2"
          variant="h1"
          id="count"
          style={{
            color: colors.primary,
            fontWeight: '900',
          }}
        >
          <CountUp end={value} />
          <LinearProgress sx={{ opacity: isLoading ? 1 : 0 }} />
        </Typography>
      </Paper>
    </Box>
  );
};
