import { Box } from '@mui/material';
import { LogoAndSpinner } from '../../components';
import colors from '../../constants/colors';

export const LoadingScreen = () => {
  return (
    <Box
      height={'100vh'}
      width={'100vw'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      bgcolor={colors.primary}
    >
      <LogoAndSpinner />
    </Box>
  );
};
