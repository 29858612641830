import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Grid, Hidden, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import colors from '../constants/colors';
import images from '../constants/images';
import { ROUTE_PATHS } from '../constants/routes';
import styles from '../constants/styles';

const MenuButton = styled(IconButton)(({ theme }) => ({
  marginLeft: -theme.spacing(1),
}));

type Props = {
  onToggleDrawer: () => void;
};

function Header(props: Props) {
  const { onToggleDrawer } = props;
  const navigate = useNavigate();

  return (
    <>
      <Hidden smUp>
        <AppBar
          position="relative"
          elevation={0}
          style={{ backgroundColor: colors.primaryDark }}
        >
          <Toolbar style={{ padding: '10px 20px' }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <MenuButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onToggleDrawer}
                >
                  <MenuIcon />
                </MenuButton>
              </Grid>
              <Grid
                item
                style={{ ...styles.header.logo }}
                onClick={() => {
                  navigate(ROUTE_PATHS.DASHBOARD);
                }}
              >
                <img
                  src={images.logo}
                  style={{ ...styles.header.logoImage }}
                  alt="ATORP Logo"
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Hidden>
    </>
  );
}

export default Header;
