import { Grid } from '@mui/material';
import { renderTextIfDefined } from '../../helpers';

type Props = {
  title: string;
  value?: string | null | undefined;
};

export function ProfileGridItem({ title, value }: Props) {
  return (
    <Grid item xs={6} sm={12} md={6} lg={6} xl={6}>
      <h3 style={{ margin: 0 }}>{title}</h3>
      <span>{renderTextIfDefined(value)}</span>
    </Grid>
  );
}
