import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { Part } from '../../api';
import {
  ArticleDataSkeleton,
  ArticleStockSkeleton,
  ArticleTitleSkeleton,
  ContactForm,
} from '../../components';
import styles from '../../constants/styles';
import {
  renderColorByInventoryBalance,
  renderTextIfDefined,
} from '../../helpers';

type Props = { article?: Part; loading: boolean; preloadedArticle?: Part };

export function ArticleInfo(props: Props) {
  const [open, setOpen] = React.useState(false);

  const { article, preloadedArticle, loading } = props || {};

  const {
    customerPartNumber,
    description,
    drawingNumber,
    extraDescription,
    inventoryBalance,
    partNumber,
    revisionNumber,
  } = article ? article : preloadedArticle || {};

  function handleClose() {
    setOpen(false);
  }

  function handleClick() {
    setOpen(true);
  }

  const showSkeletonLoader = useMemo(
    () => loading && !preloadedArticle,
    [loading, preloadedArticle]
  );

  return (
    (loading || (!loading && article)) && (
      <div style={{ ...styles.article.articlePageHeading }}>
        {showSkeletonLoader ? (
          <ArticleTitleSkeleton />
        ) : (
          <Typography
            component="h1"
            variant="h3"
            style={{ ...styles.article.articleHeading }}
          >
            {renderTextIfDefined(description)}
          </Typography>
        )}
        {showSkeletonLoader ? (
          <ArticleStockSkeleton style={{ marginTop: 10 }} />
        ) : (
          <Typography
            component="h2"
            variant="h5"
            style={{
              color:
                inventoryBalance !== undefined
                  ? renderColorByInventoryBalance(inventoryBalance).textColor
                  : undefined,
              ...styles.article.articleStock,
            }}
          >
            {`Lagersaldo: ${renderTextIfDefined(inventoryBalance)} st`}
          </Typography>
        )}
        <Grid
          container
          spacing={3}
          style={{ ...styles.article.articleInfoContainer }}
        >
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            {showSkeletonLoader ? (
              <ArticleDataSkeleton />
            ) : (
              <>
                <Typography
                  component="h2"
                  variant="h6"
                  style={{ ...styles.article.articleInfoHeading }}
                >
                  Ritningsnummer
                </Typography>
                <span style={{ ...styles.article.articleInfoText }}>
                  {renderTextIfDefined(drawingNumber)}
                </span>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            {showSkeletonLoader ? (
              <ArticleDataSkeleton />
            ) : (
              <>
                <Typography
                  component="h2"
                  variant="h6"
                  style={{ ...styles.article.articleInfoHeading }}
                >
                  Revision
                </Typography>
                <span style={{ ...styles.article.articleInfoText }}>
                  {renderTextIfDefined(revisionNumber)}
                </span>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            {showSkeletonLoader ? (
              <ArticleDataSkeleton />
            ) : (
              <>
                <Typography
                  component="h2"
                  variant="h6"
                  style={{ ...styles.article.articleInfoHeading }}
                >
                  Artnr Kund
                </Typography>

                <span style={{ ...styles.article.articleInfoText }}>
                  {renderTextIfDefined(customerPartNumber)}
                </span>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            {showSkeletonLoader ? (
              <ArticleDataSkeleton />
            ) : (
              <>
                <Typography
                  component="h2"
                  variant="h6"
                  style={{ ...styles.article.articleInfoHeading }}
                >
                  Artnr ATORP
                </Typography>

                <span style={{ ...styles.article.articleInfoText }}>
                  {renderTextIfDefined(partNumber)}
                </span>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            {showSkeletonLoader ? (
              <ArticleDataSkeleton />
            ) : (
              <>
                <Typography
                  component="h2"
                  variant="h6"
                  style={{ ...styles.article.articleInfoHeading }}
                >
                  Extra benämning
                </Typography>

                <span style={{ ...styles.article.articleInfoText }}>
                  {renderTextIfDefined(extraDescription)}
                </span>
              </>
            )}
          </Grid>
          {partNumber && description && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <span style={{ ...styles.article.articleInfoText }}>
                  <Button
                    disabled={!article}
                    onClick={handleClick}
                    color="primary"
                  >
                    Kontakta ATORP om denna artikel
                  </Button>
                </span>
              </Grid>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="contact-about-article"
              >
                <DialogActions>
                  <Button onClick={handleClose} color="secondary">
                    Stäng
                  </Button>
                </DialogActions>
                <DialogTitle
                  id="contact-about-article"
                  style={{ marginTop: 10 }}
                >
                  Kontakta ATORP om denna artikel
                </DialogTitle>
                <DialogContent style={{ paddingBottom: 25 }}>
                  <ContactForm
                    subject={`${description} (${partNumber})`}
                    showButtons={true}
                    article={`${description} (${partNumber})`}
                  />
                </DialogContent>
              </Dialog>
            </>
          )}
        </Grid>
      </div>
    )
  );
}
