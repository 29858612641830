import { Skeleton, SkeletonProps } from '@mui/material';

export const ArticleTitleSkeleton = (props: SkeletonProps) => {
  const {
    width = 600,
    height = 50,
    variant = 'rectangular',
    ...rest
  } = props || {};

  return (
    <Skeleton
      style={{ maxWidth: '100%' }}
      variant={variant}
      width={width}
      height={height}
      {...rest}
    />
  );
};
