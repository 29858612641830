import { useCallback } from 'react';
import { Customer } from '../../api';
import { signoutUser, updateUser } from '../../redux/store';
import { useAppDispatch, useAppSelector } from '../redux';

export const useUser = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  const changeCustomer = useCallback(
    (customer: Customer) => {
      let newUser = { ...user };
      newUser.customer = customer;
      dispatch(updateUser(newUser));
    },
    [dispatch, user]
  );

  const signout = useCallback(() => {
    dispatch(signoutUser());
  }, [dispatch]);

  return { user, changeCustomer, signout };
};
