/**
 * Renders a value with an optional unit, returning a dash if the value is undefined, null, or empty
 * @param value - The value to render
 * @param unit - Optional unit to append to the value
 * @returns Formatted string with value and optional unit, or '-' if value is invalid
 */
export const renderTextIfDefined = (
  value: number | string | undefined | null,
  unit?: string
): string => {
  if (value === undefined || value === null || value === '') return '-';
  return `${value}${unit ? ` ${unit}` : ''}`;
};

/**
 * Sorts two dates in descending order (newest first)
 * @param a - First date to compare
 * @param b - Second date to compare
 * @returns Negative number if b is earlier than a, positive if b is later than a, 0 if equal or invalid
 */
export const sortByDate = (
  a?: string | number | Date,
  b?: string | number | Date
) => {
  if (!a || !b) return 0;
  return new Date(b).getTime() - new Date(a).getTime();
};

/**
 * Sorts an array of objects by a specified date property in ascending or descending order
 * @param items - Array of objects containing an optional date property
 * @param dateProperty - The property name to sort by ('period' or 'transportDate')
 * @param order - Sort order ('asc' for ascending, 'desc' for descending). Defaults to 'asc'
 * @returns A new sorted array of objects, sorted by the specified date property. Original array remains unchanged
 * @template T - Type extending an object with optional period or transportDate string properties
 * @example
 * const items = [{ period: '2023-01-01' }, { period: '2023-02-01' }];
 * const sorted = sortByDateProperty(items, 'period', 'desc');
 */
export const sortByDateProperty = <
  T extends { period?: string; transportDate?: string }
>(
  items: T[],
  dateProperty: keyof T & ('period' | 'transportDate'),
  order: 'asc' | 'desc' = 'asc'
): T[] => {
  return [...items].sort((a, b) => {
    const sortResult = sortByDate(
      String(a[dateProperty]),
      String(b[dateProperty])
    );
    return order === 'asc' ? -sortResult : sortResult;
  });
};
