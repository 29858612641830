import {
  Alert,
  AlertTitle,
  Box,
  Button,
  LinearProgress,
  Tabs,
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import colors from '../constants/colors';

import {
  AlertNoDeliveriesFound,
  AlertNoOrdersFound,
  AlertNoPlannedOrdersFound,
  AlertNoPricesFound,
  ArticleInfo,
  CustomTab,
  CustomTabPanel,
  DeliveriesTable,
  OrdersTable,
  PaperContainer,
  PlanningsTable,
  PricesTable,
} from '../components';
import { ROUTE_PATHS } from '../constants/routes';
import { a11yProps } from '../helpers';
import { useArticle, useArticles } from '../hooks/api';
import { useTabs } from '../hooks/helpers';

type TabSection = {
  label: string;
  renderComponent: () => false | JSX.Element;
  renderAlert: () => React.ReactNode;
};

export function Article() {
  const { id: articleId } = useParams();
  const { articles } = useArticles();
  const preloadedArticleInfo = articles?.find(
    (article) => article.id === articleId
  );
  const { articleDetails, getArticle, loading, error } = useArticle(articleId);

  useEffect(() => {
    getArticle();
  }, [getArticle]);

  const { customerOrders, part, plannedOrders, priceInfo, shipments } =
    articleDetails || {};
  const { staggeredPrices } = priceInfo || {};

  const renderSection = useCallback(
    <T,>(data: T[] | null | undefined): data is T[] => {
      return !error && !loading && !!data && data.length > 0;
    },
    [error, loading]
  );

  const renderErrorInfo = useCallback(
    <T,>(data: T[] | null | undefined): data is T[] => {
      return !loading && (!!error || !data || data.length === 0);
    },
    [error, loading]
  );

  const TAB_SECTIONS: TabSection[] = useMemo(() => {
    return [
      {
        label: 'Planering',
        renderComponent: () =>
          renderSection(plannedOrders) && (
            <PlanningsTable plannings={plannedOrders} />
          ),
        renderAlert: () =>
          renderErrorInfo(plannedOrders) && <AlertNoPlannedOrdersFound />,
      },
      {
        label: 'Ordrar',
        renderComponent: () =>
          renderSection(customerOrders) && (
            <OrdersTable renderArticleLink={false} orders={customerOrders} />
          ),
        renderAlert: () =>
          renderErrorInfo(customerOrders) && <AlertNoOrdersFound />,
      },
      {
        label: 'Utleveranser',
        renderComponent: () =>
          renderSection(shipments) && (
            <DeliveriesTable deliveries={shipments} />
          ),
        renderAlert: () =>
          renderErrorInfo(shipments) && <AlertNoDeliveriesFound />,
      },
      {
        label: 'Priser',
        renderComponent: () =>
          renderSection(staggeredPrices) && (
            <PricesTable prices={staggeredPrices} />
          ),
        renderAlert: () =>
          renderErrorInfo(staggeredPrices) && <AlertNoPricesFound />,
      },
    ];
  }, [
    customerOrders,
    plannedOrders,
    staggeredPrices,
    shipments,
    renderSection,
    renderErrorInfo,
  ]);

  const { handleTabChange, tabValue } = useTabs();

  return (
    <Box>
      <Button component={Link} color="secondary" to={ROUTE_PATHS.ARTICLES}>
        Tillbaka till artiklar
      </Button>
      <ArticleInfo
        loading={loading}
        article={part}
        preloadedArticle={preloadedArticleInfo}
      />
      {((!loading && !articleDetails) || !!error) && (
        <Alert severity="error" variant="outlined" style={{ marginBottom: 25 }}>
          <AlertTitle>Ett fel har uppstått</AlertTitle>
          Kunde inte ladda in artikel!
        </Alert>
      )}

      <Box>
        <Box sx={{ marginBottom: 2 }}>
          <Tabs
            TabIndicatorProps={{ style: { background: colors.secondary } }}
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Article data tabs"
          >
            {TAB_SECTIONS.map(({ label }, i) => {
              return <CustomTab key={i} label={label} {...a11yProps(i)} />;
            })}
          </Tabs>
        </Box>

        <PaperContainer>
          <Box maxHeight={'35vh'} overflow={'auto'}>
            {TAB_SECTIONS.map(({ renderComponent, renderAlert }, i) => {
              return (
                <CustomTabPanel key={i} value={tabValue} index={i}>
                  {loading && <LinearProgress />}
                  {renderComponent()}
                  {renderAlert()}
                </CustomTabPanel>
              );
            })}
          </Box>
        </PaperContainer>
      </Box>
    </Box>
  );
}
