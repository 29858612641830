import { useSnackbar } from 'notistack';
import colors from '../../constants/colors';

type Variant = 'default' | 'error' | 'success';

const COLOR_VARIANT_MAP = {
  default: colors.primary,
  error: colors.error,
  success: colors.success,
};

export const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueToastMessage = (
    message: string,
    variant: Variant = 'default'
  ) => {
    enqueueSnackbar(message, {
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: { backgroundColor: COLOR_VARIANT_MAP[variant] },
      variant: variant,
      onClose: () => {},
    });
  };

  return { enqueueToastMessage };
};
