import { useNavigate } from 'react-router-dom';
import { Counter } from '../../components';
import { ROUTE_PATHS } from '../../constants/routes';
import { useDeliveries } from '../../hooks/api';

export function DeliveriesCount() {
  const { deliveries, loading } = useDeliveries();
  const navigate = useNavigate();

  return (
    <Counter
      onClick={() => navigate(ROUTE_PATHS.DELIVERIES)}
      title="Antal leveranser"
      value={deliveries?.length}
      isLoading={loading}
    />
  );
}
