import { Alert, Box, Button, TextField } from '@mui/material';
import React, { ChangeEvent, FormEvent } from 'react';
import {
  PredefinedMessage,
  message1,
  message2,
  message3,
} from '../../constants/predefinedMessages';
import { sendEmail } from '../../services/api';
import { Message, Status } from '../../types';

type Props = {
  subject?: string;
  body?: string;
  article?: string;
  showButtons?: boolean;
};

const MESSAGES_MAP: Record<Status, Message> = {
  SUCCESS: {
    showMessage: true,
    message: 'Meddelande skickat!',
    severity: 'success',
  },
  ERROR: {
    showMessage: true,
    message:
      'Ditt meddelande kunde inte skickas. Försök igen eller kontakta ATORP om problemet kvarstår.',
    severity: 'error',
  },
  IDLE: {
    showMessage: false,
    message: '',
    severity: undefined,
  },
  WARNING: {
    message: 'Du måste fylla i ett meddelande!',
    showMessage: true,
    severity: 'info',
  },
};

export function ContactForm({ subject, body, article, showButtons }: Props) {
  const [email, setEmail] = React.useState({ subject: '', body: '' });
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState<Message>(MESSAGES_MAP.IDLE);

  const isValidEmail = () => {
    const { body } = email || {};
    return body && body.trim().length > 0;
  };

  React.useEffect(() => {
    if (subject !== undefined || body !== undefined) {
      setMessage(MESSAGES_MAP.IDLE);
      setEmail({ subject: subject || '', body: body || '' });
    }
  }, [subject, body]);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setMessage(MESSAGES_MAP.IDLE);
    const { subject, body } = email || {};

    if (!isValidEmail()) {
      setMessage(MESSAGES_MAP.WARNING);
      return;
    }

    try {
      await sendEmail({ body: body, subject: subject });
      setLoading(true);
      setEmail({ subject: '', body: '' });
      setMessage(MESSAGES_MAP.SUCCESS);
      setTimeout(() => {
        setMessage(MESSAGES_MAP.IDLE);
      }, 5000);
    } catch (e) {
      setMessage(MESSAGES_MAP.ERROR);
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  function setPredefinedBody(message: PredefinedMessage) {
    const { subject, body } = message;
    setEmail({
      subject: `${subject} (${article})`,
      body: body,
    });
  }

  function handleInputChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setEmail((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  }

  return (
    <div>
      <div>
        {showButtons && (
          <Box display={'flex'} style={{ marginBottom: 25, gap: 10 }}>
            <Button
              color="secondary"
              onClick={() => setPredefinedBody(message1)}
            >
              {message1.desc}
            </Button>
            <Button
              color="secondary"
              onClick={() => setPredefinedBody(message2)}
            >
              {message2.desc}
            </Button>
            <Button
              color="secondary"
              onClick={() => setPredefinedBody(message3)}
            >
              {message3.desc}
            </Button>
          </Box>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            disabled={loading}
            id="emailSubject"
            label="Rubrik"
            name="subject"
            onChange={handleInputChange}
            placeholder="Skriv en rubrik här..."
            style={{ width: '100%', marginBottom: 25 }}
            type="text"
            value={email.subject || ''}
            variant="outlined"
          />
          <TextField
            disabled={loading}
            id="emailBody"
            label="Meddelande"
            multiline
            name="body"
            onChange={handleInputChange}
            placeholder="Skriv ditt meddelande här..."
            rows="4"
            style={{ width: '100%', marginBottom: 25 }}
            type="text"
            value={email.body || ''}
            variant="outlined"
          />

          <Button color="primary" disabled={loading} fullWidth type="submit">
            Skicka meddelande
          </Button>
        </form>
      </div>

      {message.showMessage && (
        <Alert severity={message.severity} style={{ marginTop: 25 }}>
          {message.message}
        </Alert>
      )}
    </div>
  );
}
