import { CSSProperties } from 'react';
import colors from './colors';

const base: {
  [key: string]: CSSProperties;
} = {
  textField: {
    marginBottom: 25,
  },
  selectField: {
    height: 30,
    backgroundColor: colors.lightGray,
    marginTop: 10,
  },
  pageHeadingContainer: {
    marginBottom: 50,
  },
  pageHeading: {
    fontSize: '2.5rem',
    color: colors.primary,
    fontWeight: 'bold',
  },
  pageHeadingText: {
    fontSize: '1rem',
    color: colors.gray,
    marginTop: 5,
    display: 'block',
    maxWidth: 700,
  },
};

const article: {
  [key: string]: CSSProperties;
} = {
  articlePageHeading: {
    marginBottom: 25,
    marginTop: 25,
  },
  articleHeading: {
    color: colors.primary,
    fontWeight: '900',
  },
  articleStock: {
    fontWeight: 'bold',
  },
  articleInfoContainer: {
    marginTop: 25,
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    padding: '15px 0',
  },
  articleInfoHeading: {
    fontWeight: '900',
  },
  articleInfoText: {
    color: colors.gray,
    fontSize: 16,
    maxWidth: '90%',
    display: 'block',
  },
};

const dashboard: {
  [key: string]: CSSProperties;
} = {
  segmentContainer: {},
};

const header: {
  [key: string]: CSSProperties;
} = {
  logo: {
    cursor: 'pointer',
    lineHeight: 0,
  },
  logoImage: {
    height: 25,
  },
};

const styles = {
  base,
  article,
  dashboard,
  header,
};

export default styles;
