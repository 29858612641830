import { Paper, PaperProps } from '@mui/material';
import React from 'react';

type Props = PaperProps & {
  children?: React.ReactNode;
};
export const PaperContainer = ({ children, style, ...rest }: Props) => {
  return (
    <Paper
      {...rest}
      style={{
        padding: 20,
        ...style,
      }}
    >
      {children}
    </Paper>
  );
};
