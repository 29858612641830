import { Box, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import colors from '../../constants/colors';
import { ROUTE_PATHS } from '../../constants/routes';
import { useCustomers, useUser } from '../../hooks/api';
import { getCustomerData } from '../../services/api';

export function AdminCustomerPicker() {
  const { user, changeCustomer } = useUser();
  const { customers } = useCustomers();
  const location = useLocation();
  const isOnArticlePage = location.pathname.includes('article/');
  const isOnDashboardPage = location.pathname === ROUTE_PATHS.DASHBOARD;
  const { customer } = user || {};

  async function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    let code = event.target.value;
    if (code !== '') {
      try {
        changeCustomer(customers.filter((c) => c.code === code)[0]);
        await getCustomerData();
      } catch (e) {
        console.log(e);
      }
    } else {
    }
  }

  return (
    user?.isAdmin &&
    !isOnArticlePage && (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
          {isOnDashboardPage && (
            <Typography>
              Du är inloggad som administratör i portalen och måste först välja
              en kund att arbeta med i listan.
            </Typography>
          )}
          <Box style={{ alignSelf: 'flex-end' }}>
            <select
              value={customer?.code ? customer.code : ''}
              onChange={handleSelectChange}
              style={{
                height: 32,
                backgroundColor: colors.lightGray,
                outline: 0,
                border: 0,
                margin: 0,
                padding: '0 0.25rem',
              }}
            >
              <option value="">Välj en kund</option>
              {customers?.map(({ code, name }, i) => {
                return (
                  <option value={code ?? ''} key={i}>
                    {`${code} ${name}`}
                  </option>
                );
              })}
            </select>
          </Box>
        </Box>
      </Box>
    )
  );
}
