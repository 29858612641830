import { LinearProgress, Typography } from '@mui/material';
import {
  AlertNoDeliveriesFound,
  DeliveriesTable,
  PaperContainer,
} from '../../components';
import { useDeliveries } from '../../hooks/api';

export function LatestDeliveries() {
  const { deliveries, loading } = useDeliveries();
  const latestDeliveries = deliveries.slice(0, 5);

  return (
    <>
      <Typography style={{ marginBottom: 10 }} component="h5" variant="h5">
        Senaste leveranser
      </Typography>
      <PaperContainer>
        {loading ? (
          <LinearProgress />
        ) : latestDeliveries?.length > 0 ? (
          <DeliveriesTable deliveries={latestDeliveries} />
        ) : (
          <AlertNoDeliveriesFound />
        )}
      </PaperContainer>
    </>
  );
}
