import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { StaggeredPrice } from '../../api';
import { renderTextIfDefined } from '../../helpers';

type Props = { prices: StaggeredPrice[] };

export const PricesTable = ({ prices }: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head">Antal från</TableCell>
            <TableCell variant="head">Pris</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prices.map((p, i) => {
            const { lowerBoundaryQuantity, price } = p || {};
            return (
              <TableRow key={i}>
                <TableCell>
                  {renderTextIfDefined(lowerBoundaryQuantity, 'st')}
                </TableCell>
                <TableCell>{renderTextIfDefined(price, 'kr/st')}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
