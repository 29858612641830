import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Center, DeliveryContentTable, ErrorRetry } from '..';
import { ShipmentDetails } from '../../api';
import colors from '../../constants/colors';
import { delay, formatDate, renderTextIfDefined } from '../../helpers';
import { useDeliveries, useUser } from '../../hooks/api';

type Props = {
  shipmentId?: string;
  open: boolean;
  onClose: () => void;
};

export const DeliveryDetailsDialog = ({ shipmentId, open, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);
  const [shipmentDetails, setShipmentDetails] =
    useState<ShipmentDetails | null>(null);
  const { user } = useUser();
  const { customer } = user || {};
  const { id: customerId } = customer || {};
  const { getDeliveryDetails } = useDeliveries();

  const handleGetShipmentDetails = useCallback(
    async (customerId?: string, shipmentId?: string) => {
      if (!customerId || !shipmentId) {
        return;
      }
      try {
        setError(null);
        setIsLoading(true);
        await delay(250);
        const data = await getDeliveryDetails(customerId, shipmentId);
        setShipmentDetails(data);
      } catch (e) {
        console.error(e);
        setError(e);
      } finally {
        setIsLoading(false);
      }
    },
    [getDeliveryDetails]
  );

  useEffect(() => {
    open && handleGetShipmentDetails(customerId, shipmentId);
  }, [shipmentId, customerId, handleGetShipmentDetails, open]);

  const { contents, shipment } = shipmentDetails || {};
  const { consignmentNumber, courier, numberOfPackages, transportDate } =
    shipment || {};

  const shipmentMetadata = [
    {
      label: 'Datum',
      value: renderTextIfDefined(transportDate && formatDate(transportDate)),
    },
    {
      label: 'Antal kollin',
      value: renderTextIfDefined(numberOfPackages, 'st'),
    },
    {
      label: 'Speditör',
      value: renderTextIfDefined(courier),
    },
    {
      label: 'Frakt#',
      value: renderTextIfDefined(consignmentNumber),
    },
  ];

  if (!shipmentId) {
    return;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box display={'flex'} justifyContent={'space-between'}>
        <DialogTitle variant="h5">Leveransinformation</DialogTitle>
        <Box p={1}>
          <Button color="secondary" onClick={onClose}>
            Stäng
          </Button>
        </Box>
      </Box>
      <DialogContent>
        {isLoading && (
          <Center padding={4}>
            <CircularProgress size={48} sx={{ color: colors.secondary }} />
          </Center>
        )}
        {!error && !isLoading && (
          <>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              gap={4}
            >
              {shipmentMetadata.map(({ label, value }) => {
                return (
                  <Box key={label} flex={'auto'}>
                    <Typography variant="h6" sx={{ fontWeight: '900' }}>
                      {label}
                    </Typography>
                    <Typography>{value}</Typography>
                  </Box>
                );
              })}
            </Box>
            {contents && (
              <Box marginTop={1.5}>
                <DeliveryContentTable content={contents} />
              </Box>
            )}
          </>
        )}
        {!!error && !isLoading && (
          <ErrorRetry
            errorMessage="Kunde inte hämta leveransinformation!"
            onRetry={() => {
              handleGetShipmentDetails(customerId, shipmentId);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
