import { Typography } from '@mui/material';
import styles from '../../constants/styles';

type Props = {
  heading: string;
  text?: string;
};

export function PageHeader(props: Props) {
  return (
    <div style={{ ...styles.base.pageHeadingContainer }}>
      <Typography
        component="h1"
        variant="h4"
        style={{ ...styles.base.pageHeading }}
      >
        {props.heading}
      </Typography>
      <span style={{ ...styles.base.pageHeadingText }}>{props.text}</span>
    </div>
  );
}
