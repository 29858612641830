import { useCallback } from 'react';
import { getOrdersByCustomer } from '../../services/api';
import { useAppSelector } from '../redux/useAppSelector';

export const useOrders = () => {
  const getOrders = useCallback(async () => {
    await getOrdersByCustomer();
  }, []);

  const orders = useAppSelector((state) => state.orders);
  return { ...orders, getOrders };
};
