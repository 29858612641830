import { createTheme } from '@mui/material';
import { darken, lighten } from '@mui/system';
import colors from './colors';

export let theme = createTheme({
  palette: {
    primary: {
      light: lighten(colors.primary, 0.1),
      main: colors.primary,
      dark: darken(colors.primary, 0.1),
    },
    secondary: {
      light: lighten(colors.secondary, 0.1),
      main: colors.secondary,
      dark: darken(colors.secondary, 0.1),
    },
  },
  typography: {
    fontFamily: ['Varela Round'].join(','),
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.primary,
          height: '100%',
        },
      },
    },
    MuiButton: {
      defaultProps: { color: 'primary', variant: 'contained' },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 5,
          display: 'inline-block',
          marginBottom: 0,
          textDecoration: 'none',
          fontSize: 14,
          padding: 10,
          lineHeight: 1,
          cursor: 'pointer',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: colors.primary,
            color: colors.white,
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: colors.secondary,
            color: colors.white,
          },
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: colors.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginRight: 16,
          marginLeft: 16,
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          maxHeight: 'auto',
          padding: 4,
          margin: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.darkGray,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: 16,
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiLinearProgress: {
      defaultProps: { variant: 'query', color: 'secondary' },
      styleOverrides: {
        root: {
          height: 6,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
    },

    MuiTableCell: {
      defaultProps: {
        variant: 'body',
      },
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
      variants: [
        {
          props: { variant: 'body' },
          style: {},
        },
        {
          props: { variant: 'head' },
          style: {
            fontWeight: 'bold',
          },
        },
      ],
    },

    MuiAlert: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            gap: 12,
            '& .MuiAlert-message': {
              padding: 0,
            },
            '& .MuiAlert-action': {
              padding: 0,
              marginRight: 0,
              fill: 'currentColor',
            },
            '& .MuiAlert-icon': {
              margin: 0,
              marginRight: 0,
            },
          },
        },
      ],
    },
  },
};
