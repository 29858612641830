import { PageHeader, PaperContainer, VideoPlayer } from '../components';

export function PortalInfo() {
  return (
    <>
      <PageHeader heading={'Introduktionsfilm Kundportal'} />
      <PaperContainer className="Portal101">
        <>
          <VideoPlayer
            url="https://vimeo.com/539190687"
            showAsModal={false}
            coverUrl={require('../assets/cover.jpg')}
          />
        </>
      </PaperContainer>
    </>
  );
}
