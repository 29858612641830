import { Tab, TabProps } from '@mui/material';
import colors from '../../constants/colors';

export const CustomTab = (props: TabProps) => {
  const { ...rest } = props || {};
  return (
    <Tab
      disableRipple
      style={{ fontSize: '1.25rem', color: colors.black }}
      {...rest}
    />
  );
};
