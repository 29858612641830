import {
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

import {
  AccountCircle,
  BarChart,
  Chat,
  ComputerOutlined,
  Favorite,
  LocalShipping,
  PowerSettingsNew,
  Receipt,
  SupervisorAccount,
} from '@mui/icons-material';
import { NavLink, To, useNavigate } from 'react-router-dom';
import colors from '../constants/colors';
import images from '../constants/images';
import { ROUTE_PATHS } from '../constants/routes';
import { useUser } from '../hooks/api';

const LogoContainer = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  backgroundColor: colors.primaryDark,
  pointer: 'cursor',
  gap: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: colors.primary,
  color: colors.white,
  '&:hover,&:focus': {
    backgroundColor: colors.primaryLight,
  },
}));

const StyledListItemLink = styled(NavLink)(() => ({
  color: colors.white,
  textDecoration: 'none',
  paddingTop: 10,
  paddingBottom: 10,
  display: 'block',
  fontSize: 'inherit',
}));

type Props = {
  onToggleDrawer?: () => void;
} & DrawerProps;

function Navigator(props: Props) {
  const navigate = useNavigate();
  const { user } = useUser();
  const { isAdmin } = user || {};
  const { onToggleDrawer, ...rest } = props || {};

  const pages: Array<{
    id: string;
    children: Array<{
      title: string;
      path: To;
      icon: React.ReactNode;
    }>;
  }> = [
    {
      id: 'Portal',
      children: [
        { title: 'Översikt', path: ROUTE_PATHS.DASHBOARD, icon: <BarChart /> },
        {
          title: 'Dina artiklar',
          path: ROUTE_PATHS.ARTICLES,
          icon: <Favorite />,
        },
        {
          title: 'Dina leveranser',
          path: ROUTE_PATHS.DELIVERIES,
          icon: <LocalShipping />,
        },
        { title: 'Dina ordrar', path: ROUTE_PATHS.ORDERS, icon: <Receipt /> },
        { title: 'Kontakta ATORP', path: ROUTE_PATHS.MESSAGES, icon: <Chat /> },
        {
          title: 'Introduktionsfilm Kundportal',
          path: ROUTE_PATHS.PORTAL_INFO,
          icon: <ComputerOutlined />,
        },
        {
          title: 'Min Profil',
          path: ROUTE_PATHS.PROFILE,
          icon: <AccountCircle />,
        },
        ...(isAdmin
          ? [
              {
                title: 'Admin',
                path: ROUTE_PATHS.ADMIN,
                icon: <SupervisorAccount />,
              },
            ]
          : []),
      ],
    },
  ];

  return (
    <Drawer variant="permanent" {...rest}>
      <List disablePadding>
        <LogoContainer
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onToggleDrawer && onToggleDrawer();
            navigate(ROUTE_PATHS.DASHBOARD);
          }}
        >
          <img src={images.logo} style={{ width: '8rem' }} alt="logo" />
          <img src={images.slogan} style={{ width: '8rem' }} alt="slogan" />
        </LogoContainer>
        {pages.map(({ id, children }) => (
          <React.Fragment key={id}>
            {children.map(({ title, path, icon }) => (
              <StyledListItem key={title}>
                <ListItemIcon sx={{ color: colors.white }}>{icon}</ListItemIcon>
                <ListItemText>
                  <StyledListItemLink
                    onClick={() => onToggleDrawer && onToggleDrawer()}
                    to={path}
                  >
                    {title}
                  </StyledListItemLink>
                </ListItemText>
              </StyledListItem>
            ))}
            <StyledListItem>
              <ListItemIcon sx={{ color: colors.white }}>
                <PowerSettingsNew />
              </ListItemIcon>
              <ListItemText>
                <StyledListItemLink to={ROUTE_PATHS.SIGN_OUT_OIDC}>
                  Logga ut
                </StyledListItemLink>
              </ListItemText>
            </StyledListItem>
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default Navigator;
