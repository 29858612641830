import { Box, CircularProgress } from '@mui/material';
import images from '../../constants/images';

export const LogoAndSpinner = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
      gap={'2rem'}
    >
      <img height={'75rem'} src={images.logo} alt="Logo" />
      <CircularProgress size={'4rem'} />
    </Box>
  );
};
