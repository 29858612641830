import { ArrowForward } from '@mui/icons-material';
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { DeliveryDetailsDialog } from '..';
import { Shipment } from '../../api';
import colors from '../../constants/colors';
import { formatDate, renderTextIfDefined } from '../../helpers';
import { useDeliveries, useUser } from '../../hooks/api';
import { useDisclosure, useToast } from '../../hooks/helpers';

type Props = { deliveries: Shipment[] };

export const DeliveriesTable = (props: Props) => {
  const { enqueueToastMessage } = useToast();

  const { deliveries } = props || {};
  const { openShipmentTrackingLink } = useDeliveries();
  const { user } = useUser();
  const { customer } = user || {};
  const { id: customerId } = customer || {};

  const [selectedShipmentId, setSelectedShipmentId] = useState<string>();

  const {
    isOpen: dialogIsOpen,
    onClose: onDialogClose,
    onOpen: onDialogOpen,
  } = useDisclosure();

  const handleOnClickShipmentTrackingLink = async (
    shipmentId?: string,
    customerId?: string
  ) => {
    try {
      if (!customerId || !shipmentId) {
        return;
      }

      await openShipmentTrackingLink(shipmentId, customerId);
    } catch (e) {
      enqueueToastMessage('Spårningslänken saknas eller fungerar ej!', 'error');
      console.error(e);
    }
  };

  const handleOnClickDeliveryOrder = (id?: string) => {
    if (!id) {
      return;
    }
    setSelectedShipmentId(id);
    onDialogOpen();
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">Datum</TableCell>
              <TableCell variant="head">Antal kollin</TableCell>
              <TableCell variant="head">Speditör</TableCell>
              <TableCell variant="head">Frakt#</TableCell>
              <TableCell variant="head">Spåra</TableCell>
              <TableCell variant="head"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deliveries.map((shipment, i) => {
              const {
                consignmentNumber,
                courier,
                id,
                numberOfPackages,
                transportDate,
              } = shipment || {};
              return (
                <TableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  key={i}
                  onClick={() => {
                    handleOnClickDeliveryOrder(id);
                  }}
                >
                  <TableCell>
                    {transportDate && formatDate(transportDate)}
                  </TableCell>
                  <TableCell>
                    {renderTextIfDefined(numberOfPackages, 'st')}
                  </TableCell>
                  <TableCell>{renderTextIfDefined(courier)}</TableCell>
                  <TableCell>
                    {renderTextIfDefined(consignmentNumber)}
                  </TableCell>
                  <TableCell>
                    <Link
                      component="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnClickShipmentTrackingLink(customerId, id);
                      }}
                    >
                      Länk
                    </Link>
                  </TableCell>
                  <TableCell sx={{ padding: 0, paddingRight: 2 }} align="right">
                    <IconButton onClick={() => handleOnClickDeliveryOrder(id)}>
                      <ArrowForward
                        sx={{ color: colors.black, fontSize: 20 }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DeliveryDetailsDialog
        shipmentId={selectedShipmentId}
        open={dialogIsOpen}
        onClose={onDialogClose}
      />
    </>
  );
};
