import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ShipmentContent } from '../../api';
import { renderTextIfDefined } from '../../helpers';

type Props = { content: ShipmentContent[] };

export const DeliveryContentTable = ({ content }: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head">Artikel#</TableCell>
            <TableCell variant="head">Kund Artikel#</TableCell>
            <TableCell variant="head">Order#</TableCell>
            <TableCell variant="head">Kund Order#</TableCell>
            <TableCell variant="head">Antal artiklar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {content.map((contentRow, i) => {
            const {
              customerOrderNumber,
              customerPartNumber,
              orderId,
              partNumber,
              quantity,
            } = contentRow || {};
            return (
              <TableRow key={i}>
                <TableCell>{renderTextIfDefined(partNumber)}</TableCell>
                <TableCell>{renderTextIfDefined(customerPartNumber)}</TableCell>
                <TableCell>{renderTextIfDefined(orderId)}</TableCell>
                <TableCell>
                  {renderTextIfDefined(customerOrderNumber)}
                </TableCell>
                <TableCell>{renderTextIfDefined(quantity, 'st')}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
