import { FC, PropsWithChildren } from 'react';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';

const AUTH_URL =
  process.env.REACT_APP_AUTH_URL ??
  'https://app-atorp-dev-secentral.azurewebsites.net';
const APP_URL = process.env.REACT_APP_REDIRECT_URL ?? 'https://localhost:3000';
const CLIENT_ID = process.env.REACT_APP_IDENTITY_CLIENT_ID ?? 'portal.atorp.se';

const OID_CONFIG: AuthProviderProps = {
  authority: AUTH_URL,
  client_id: CLIENT_ID,
  redirect_uri: APP_URL + '/signin-oidc',
  response_type: 'code',
  scope: 'offline_access',
  post_logout_redirect_uri: APP_URL,
  automaticSilentRenew: true,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

export const OIDAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  return <AuthProvider {...OID_CONFIG}>{children}</AuthProvider>;
};
