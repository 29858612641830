import { Box, Button, Typography } from '@mui/material';

type Props = {
  onRetry: () => void;
  errorMessage?: string;
  buttonText?: string;
};

export const ErrorRetry = ({
  onRetry,
  errorMessage = 'Ett fel inträffade!',
  buttonText = 'Försök igen',
}: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={4}
      textAlign="center"
    >
      <Typography color="error" mb={2}>
        {errorMessage}
      </Typography>
      <Button onClick={onRetry} color="secondary">
        {buttonText}
      </Button>
    </Box>
  );
};
