import { Grid } from '@mui/material';
import {
  ArticlesCount,
  DeliveriesCount,
  LatestDeliveries,
  LatestOrders,
  OrdersCount,
  PageHeader,
} from '../components';
import { useUser } from '../hooks/api';

export function Dashboard() {
  const { user } = useUser();

  return (
    <>
      {user && (
        <PageHeader
          heading={user?.name ? `Hej ${user.name}!` : 'Hej!'}
          text={
            'Välkommen till portalen! Här hittar du det mesta om dina artiklar hos oss. Du kan bland annat gå in på enstaka artiklar, se ordrar, planering och dessutom spåra leveranser från oss.'
          }
        />
      )}
      <div>
        <Grid container spacing={6} style={{ marginBottom: 50 }}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <ArticlesCount />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <OrdersCount />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <DeliveriesCount />
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <LatestOrders />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LatestDeliveries />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
