import { Alert, AlertProps, AlertSlotsAndSlotProps } from '@mui/material';

type Props = { children: string } & AlertProps & AlertSlotsAndSlotProps;

export const AlertMessage = (props: Props) => {
  const { children, severity = 'info', ...rest } = props || {};
  return (
    <Alert severity={severity} {...rest}>
      {children}
    </Alert>
  );
};
