import {
  ChangePasswordRequest,
  ContactRequest,
  ContactService,
  CustomerOrdersService,
  CustomersService,
  MeService,
  PartsService,
  ShipmentsService,
  UsersService,
} from '../api';
import { getSessionCustomerCode } from '../helpers';
import {
  resetArticles,
  resetDeliveries,
  resetOrders,
  setArticles,
  setCustomers,
  setDeliveries,
  setOrders,
  setUser,
  setUsers,
  store,
} from '../redux/store';

/**
 * Fetches current user data and updates the store.
 * If the user is an admin, it also fetches the customer list and set the session customer.
 * @returns The user data
 */
const getCurrentUserData = async () => {
  try {
    let user = await MeService.getMe();
    if (user?.isAdmin) {
      const sessionCustomerCode = getSessionCustomerCode();
      if (sessionCustomerCode) {
        const customers = await getCustomerList();
        const customer = customers.find(
          (customer) => customer.code === sessionCustomerCode
        );
        user.customer = customer ?? undefined;
      }
    }
    store.dispatch(setUser(user));
    return user;
  } catch (e) {
    console.log('Error getting current user data', e);
    throw e;
  }
};

/**
 * Sends an email using the contact service
 * @param props Contact request data
 */
async function sendEmail(props: ContactRequest) {
  try {
    await ContactService.postContact(props);
  } catch (e) {
    console.log('Error sending email', e);
    throw e;
  }
}

/**
 * Fetches all parts for the current customer and updates the store
 */
async function getAllParts() {
  try {
    const { user } = store.getState().user;
    const { customer } = user || {};
    if (customer?.id) {
      store.dispatch(resetArticles());
      const parts = await PartsService.getCustomersParts(customer.id);
      store.dispatch(setArticles(parts));
    }
  } catch (e) {
    console.log('Error getting all parts', e);
    throw e;
  }
}

/**
 * Fetches orders for the current customer and updates the store
 */
async function getOrdersByCustomer() {
  try {
    const { user } = store.getState().user;
    const { customer } = user || {};
    if (customer?.id) {
      store.dispatch(resetOrders());
      const orders = await CustomerOrdersService.getCustomersOrders(
        customer.id
      );
      store.dispatch(setOrders(orders));
    }
  } catch (e) {
    console.log('Error getting orders by customer', e);
    throw e;
  }
}

/**
 * Fetches deliveries for the current customer and updates the store
 */
async function getDeliveriesByCustomer() {
  try {
    const { user } = store.getState().user;
    const { customer } = user || {};
    if (customer?.id) {
      store.dispatch(resetDeliveries());
      const deliveries = await ShipmentsService.getCustomersShipments(
        customer.id
      );
      store.dispatch(setDeliveries(deliveries));
    }
  } catch (e) {
    console.log('Error getting deliveries by customer', e);
    throw e;
  }
}

/**
 * Gets the tracking link for a specific shipment
 * @param customerId The customer ID
 * @param shipmentId The shipment ID
 * @returns The tracking link if available
 */
async function getShipmentTrackingLink(customerId: string, shipmentId: string) {
  try {
    const { trackingLink } =
      (await ShipmentsService.getCustomersShipmentsGetTrackingLink(
        customerId,
        shipmentId
      )) || {};
    return trackingLink;
  } catch (e) {
    console.log('Error getting shipment tracking link:', e);
  }
}

/**
 * Fetches all customer-related data including parts, orders, and deliveries
 */
async function getCustomerData() {
  await getAllParts();
  await getOrdersByCustomer();
  await getDeliveriesByCustomer();
}

/**
 * Fetches the list of customers and updates the store
 * @returns The list of customers
 */
async function getCustomerList() {
  try {
    const customers = await CustomersService.getCustomers();
    if (customers) {
      store.dispatch(setCustomers(customers));
    }
    return customers;
  } catch (e) {
    console.log('Error getting customer list', e);
    throw e;
  }
}

/**
 * Fetches the list of users and updates the store
 */
async function getUserList() {
  try {
    const users = await UsersService.getUsers();
    if (users) {
      store.dispatch(setUsers(users));
    }
  } catch (e) {
    console.log('Error getting user list', e);
    throw e;
  }
}

/**
 * Fetches details for a specific article/part
 * @param partNr The part number
 * @returns The article details if available
 */
async function getArticleDetails(partNr: string) {
  try {
    const { user } = store.getState().user;
    const { customer } = user || {};
    if (customer?.id) {
      const articleDetails = await PartsService.getCustomersPartsDetails(
        customer.id,
        partNr
      );

      return articleDetails;
    }
  } catch (e) {
    console.log('Error getting article details', e);
    throw e;
  }
}

/**
 * Changes the user's password
 * @param oldPassword The current password
 * @param newPassword The new password
 */
async function changePassword({
  oldPassword,
  newPassword,
}: ChangePasswordRequest) {
  try {
    await MeService.postMeChangePassword({
      newPassword: newPassword,
      oldPassword: oldPassword,
    });
  } catch (e) {
    console.log('Error changing password', e);
    throw e;
  }
}

/**
 * Initializes the application by fetching user data, customer list, and customer-specific data
 */
async function initApp() {
  try {
    const user = await getCurrentUserData();
    await getCustomerData();
    if (user?.isAdmin) {
      await getCustomerList();
      await getUserList();
    }
  } catch (e) {
    console.log('Error init app', e);
    throw e;
  }
}
export {
  changePassword,
  getAllParts,
  getArticleDetails,
  getCurrentUserData,
  getCustomerData,
  getCustomerList,
  getDeliveriesByCustomer,
  getOrdersByCustomer,
  getShipmentTrackingLink,
  initApp,
  sendEmail,
};
