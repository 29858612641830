import { SyntheticEvent, useState } from 'react';

export const useTabs = (
  initialTab: number = 0
): {
  tabValue: number;
  handleTabChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
} => {
  const [tabValue, setTabValue] = useState(initialTab);

  const handleTabChange:
    | ((event: SyntheticEvent<Element, Event>, value: any) => void)
    | undefined = (_, newValue) => {
    setTabValue(newValue);
  };

  return {
    tabValue,
    handleTabChange,
  };
};
