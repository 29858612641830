import { Alert, Box, Button } from '@mui/material';
import React, { ChangeEvent, MouseEvent } from 'react';
import { PasswordInput } from '..';
import { changePassword } from '../../services/api';
import { Message, Status } from '../../types';

type PasswordStatus = Status | 'WARNING_WRONG_PASSWORD';

const MESSAGES_MAP: Record<PasswordStatus, Message> = {
  SUCCESS: {
    showMessage: true,
    message: 'Ditt lösenord är nu bytt!',
    severity: 'success',
  },
  ERROR: {
    showMessage: true,
    message:
      'Ditt lösenord gick inte att byta, vänlig kontrollera dina uppgifter och försök igen.',
    severity: 'error',
  },
  IDLE: {
    showMessage: false,
    message: '',
    severity: undefined,
  },
  WARNING: {
    showMessage: true,
    message: 'Du måste fylla i alla lösenordsfält!',
    severity: 'warning',
  },
  WARNING_WRONG_PASSWORD: {
    showMessage: true,
    message: 'Ditt nya lösenord matchar inte!',
    severity: 'warning',
  },
};

export function ChangePasswordForm() {
  const [message, setMessage] = React.useState<Message>(MESSAGES_MAP.IDLE);
  const [loading, setLoading] = React.useState(false);

  const [passwords, setPasswords] = React.useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const { oldPassword, newPassword, confirmPassword } = passwords || {};

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  }

  function areValidPasswords() {
    if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
      setMessage(MESSAGES_MAP.WARNING);
      setLoading(false);
      return false;
    }

    if (newPassword !== confirmPassword) {
      setMessage(MESSAGES_MAP.WARNING_WRONG_PASSWORD);
      setLoading(false);
      return false;
    }
    return true;
  }

  async function handleSubmit(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setMessage(MESSAGES_MAP.IDLE);
    if (!areValidPasswords()) return;
    try {
      setLoading(true);
      await changePassword({
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
      setMessage(MESSAGES_MAP.SUCCESS);
      setPasswords({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      setTimeout(() => {
        setMessage(MESSAGES_MAP.IDLE);
      }, 5000);
    } catch (e) {
      setMessage(MESSAGES_MAP.ERROR);
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <form>
        <Box display={'flex'} flexDirection={'column'} gap={3}>
          <PasswordInput
            label="Nuvarande Lösenord"
            name="oldPassword"
            autoComplete="current-password"
            value={oldPassword}
            onChange={handlePasswordChange}
          />
          <PasswordInput
            label="Nytt Lösenord"
            name="newPassword"
            autoComplete="new-password"
            value={newPassword}
            onChange={handlePasswordChange}
          />
          <PasswordInput
            label="Bekräfta Lösenord"
            name="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={handlePasswordChange}
          />

          {message?.showMessage && (
            <Alert severity={message.severity}>{message.message}</Alert>
          )}

          <Button disabled={loading} fullWidth onClick={handleSubmit}>
            Byt lösenord
          </Button>
        </Box>
      </form>
    </>
  );
}
