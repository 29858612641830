import { useCallback } from 'react';
import { ShipmentsService } from '../../api';
import {
  getDeliveriesByCustomer,
  getShipmentTrackingLink,
} from '../../services/api';
import { useAppSelector } from '../redux/useAppSelector';

export const useDeliveries = () => {
  const getDeliveries = useCallback(async () => {
    await getDeliveriesByCustomer();
  }, []);

  const openShipmentTrackingLink = useCallback(
    async (customerId: string, shipmentId: string) => {
      const { url } =
        (await getShipmentTrackingLink(customerId, shipmentId)) || {};
      if (url) {
        window.open(url, '_blank');
        return;
      }
      throw new Error('Shipment tracking link does not exist');
    },
    []
  );

  const getDeliveryDetails = useCallback(
    async (customerId: string, shipmentId: string) => {
      return await ShipmentsService.getCustomersShipmentsDetails(
        customerId,
        shipmentId
      );
    },
    []
  );

  const deliveries = useAppSelector((state) => state.deliveries);
  return {
    ...deliveries,
    getDeliveries,
    getDeliveryDetails,
    openShipmentTrackingLink,
  };
};
