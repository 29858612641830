import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { PlannedOrder } from '../../api';
import {
  formatDate,
  renderColorByInventoryBalance,
  renderColorByOrderType,
  renderTextIfDefined,
} from '../../helpers';

type Props = { plannings: PlannedOrder[] };

export const PlanningsTable = (props: Props) => {
  const { plannings } = props || {};

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head">Datum</TableCell>
            <TableCell variant="head">Typ</TableCell>
            <TableCell variant="head">Order#</TableCell>
            <TableCell variant="head">Beställt</TableCell>
            <TableCell variant="head">Saldo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {plannings.map((planning, i) => {
            const {
              inventoryBalanceAfterOrder,
              orderNumber,
              orderTypeInfo,
              orderedAmount,
              period,
            } = planning;

            const { orderType, displayName } = orderTypeInfo || {};

            return (
              <TableRow
                key={i}
                style={{
                  backgroundColor:
                    orderType && renderColorByOrderType(orderType).background,
                }}
              >
                <TableCell>{period && formatDate(period)}</TableCell>
                <TableCell
                  style={{
                    color:
                      orderType && renderColorByOrderType(orderType).textColor,
                  }}
                >
                  {renderTextIfDefined(displayName)}
                </TableCell>
                <TableCell>{renderTextIfDefined(orderNumber)}</TableCell>
                <TableCell>
                  {renderTextIfDefined(orderedAmount, 'st')}
                </TableCell>
                <TableCell
                  style={{
                    color:
                      typeof inventoryBalanceAfterOrder === 'number'
                        ? renderColorByInventoryBalance(
                            inventoryBalanceAfterOrder,
                            'inherit'
                          ).textColor
                        : undefined,
                  }}
                >
                  {renderTextIfDefined(inventoryBalanceAfterOrder, 'st')}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
