import { Box, LinearProgress, TextField } from '@mui/material';
import { useMemo } from 'react';
import { Part } from '../api';
import { ArticlesTable, PageHeader, PaperContainer } from '../components';
import { useArticles } from '../hooks/api';
import { useFilterArray, useSearchQuery } from '../hooks/helpers';

export function Articles() {
  const { handleOnChange, query } = useSearchQuery();

  const { articles, loading } = useArticles();

  const propertyNames: (keyof Part)[] = useMemo(
    () =>
      [
        'customerPartNumber',
        'description',
        'drawingNumber',
        'extraDescription',
        'id',
        'partNumber',
        'revisionNumber',
      ] as (keyof Part)[],
    []
  );

  const filteredArticles = useFilterArray(articles, query, propertyNames);

  return (
    <Box>
      <PageHeader
        heading={'Artiklar hos ATORP'}
        text={
          'I listan ser du alla din artiklar hos ATORP. Du kan även sortera tabellen, eller klicka dig in på en specifik artikel.'
        }
      />
      <PaperContainer>
        <TextField
          id="outlined-basic"
          label="Skriv in vad du vill söka på..."
          variant="outlined"
          style={{ marginBottom: 16 }}
          fullWidth
          value={query}
          onChange={handleOnChange}
        />
        {loading && <LinearProgress />}
        {!loading && <ArticlesTable data={filteredArticles} />}
      </PaperContainer>
    </Box>
  );
}
