import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader, UserTableContainer } from '../components';
import { ROUTE_PATHS } from '../constants/routes';
import { useUser } from '../hooks/api';

export const Admin = () => {
  const { user } = useUser();
  const { isAdmin } = user || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      navigate(ROUTE_PATHS.DASHBOARD);
    }
  }, [isAdmin, navigate]);

  if (!isAdmin) {
    return;
  }
  return (
    <Box>
      <PageHeader
        heading="Administration"
        text="Här kan du se och administrera användare som finns i kundportalen."
      />
      <Box gap={4} display="flex" flexDirection="column">
        <UserTableContainer />
      </Box>
    </Box>
  );
};
