import { UserProfile } from '../api';

export const LOCAL_STORAGE_KEYS = {
  USER: 'user',
  CUSTOMER_CODE: 'customerCode',
};

/**
 * Retrieves the user profile from local storage
 * @returns {UserProfile | null} UserProfile object if found, null otherwise
 */
export const getUserLocalStorage = (): UserProfile | null => {
  const userStringified = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);
  return userStringified ? (JSON.parse(userStringified) as UserProfile) : null;
};

/**
 * Stores the user profile in local storage
 * @param {UserProfile} user - The user profile to store
 * @returns {void}
 */
export const setUserLocalStorage = (user: UserProfile) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
};

/**
 * Sets the customer code in local storage
 * @param {string} customer - The customer code to store
 * @returns {void}
 */
export const setSessionCustomerCode = (customer: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.CUSTOMER_CODE, customer);
};

/**
 * Retrieves the customer code from local storage
 * @returns {string | null} The customer code if found, null otherwise
 */
export const getSessionCustomerCode = (): string | null => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.CUSTOMER_CODE);
};
