import { LinearProgress } from '@mui/material';
import {
  AlertNoOrdersFound,
  OrdersTable,
  PageHeader,
  PaperContainer,
} from '../components';
import { useOrders } from '../hooks/api';

export function Orders() {
  const { orders, loading } = useOrders();

  return (
    <>
      <PageHeader
        heading={'Ordrar från ATORP'}
        text={'Här ser du samtliga ordrar från ATORP'}
      />

      <PaperContainer className="Messages">
        {loading ? (
          <LinearProgress />
        ) : orders?.length > 0 ? (
          <OrdersTable orders={orders} />
        ) : (
          <AlertNoOrdersFound />
        )}
      </PaperContainer>
    </>
  );
}
